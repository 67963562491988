body>header .page-header {
	margin: 0 0 @grid-gutter-width * 2;
	padding: 0;
	border-bottom: 0 none;
	background: url("@{images-url}bg-header.jpg") 50% 50% no-repeat;
	background-size: cover;
	.slider-box-shadow;

	.container {
		padding-top: @grid-gutter-width * 2.5;
		padding-bottom: @grid-gutter-width * 2.5;
	}
	
	@media (max-width: @screen-sm-max){
		padding-top: 20px;
		padding-bottom: 30px;
		margin-bottom: @grid-gutter-width;
	}

	h1 {
		color: @white;
		.font-black;
		
		&:after {
			width: 15%;
		}
	}
	
	+* {
		margin-top: -@grid-gutter-width;
	}
}

.termek.adatlap .page-header {
	border: 0 none;
	padding: 0;
	margin-top: 0;
	
	h1 {
		margin-top: 0;
		.font-black;
		
		&:after {
			width: 25%;
		}
	}
}

// Description Lists
dt,dd {
	padding-top: .4em;
	margin-top: .4em;
}
dt {
}

// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).

.dl-horizontal {
  dd {
    &:extend(.clearfix all); // Clear the floated `dt` if an empty `dd` is present
  }

  @media (min-width: @grid-float-breakpoint) {
	dt {
	  width: @dl-horizontal-offset;
	  text-align: left;
	  .font-normal;
	  
	  &:first-child+dd {
		  background: none;
	  }
	}
	dd {
	  margin-left: @dl-horizontal-offset;
	}
	
	dt,dd {
		&:not(:first-child) {
			background: @lista-border 0 0 repeat-x;
		}
	}
  }
}