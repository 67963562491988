//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  font-size: @font-size-large;
  line-height: 28px;
  margin-bottom: 0;
  .font-light;

  li:not(:last-child) a {
	  color: @breadcrumb-color;
  }
  
  > .active {
    color: @breadcrumb-active-color;
  }
}
