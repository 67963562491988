//
// Buttons
// --------------------------------------------------

.btn {
	.button-size(@padding-base-vertical; @padding-base-horizontal; 16px; 30px; @border-radius-base);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @black;
  .font-normal;
  font-size: @font-size-large;
  text-transform: uppercase;
  border-radius: 0;
  //padding: 3px .5em;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @text-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}
