.vertical-align {
	font-size: 0;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	.vertical-align-middle {
		vertical-align: middle;
		display: inline-block;
		max-width: 100%;
	}

	.vertical-align-bottom {
		vertical-align: bottom;
		display: inline-block;
		max-width: 100%;
	}
}

.bg-feher {
	background-color: @white;
}

.bg-piros {
	background-color: @piros;
}

.bg-majdnemszurke {
	background-color: @majdnemszurke;
}

.box {
	.panel {
		border: 0 none;
		box-shadow: none;
	}
	
	.panel-title {
		color: @white;
		font-size: 1.8em;
		line-height: 50px;
		text-transform: uppercase;
		
		a:hover, a:focus, a:active {
			text-decoration: none;
		}
		
		&:after {
			display: none;
			content: none;
		}
	}
	
	svg {
		background: @white;
		.pull-left;
		margin-right: @grid-gutter-width / 2;
	}
	
	&.olajvalaszto {
		.bg-piros;
		
		.panel-body {
			&, a, a:hover, a:visited, a:active {
				color: lighten(@piros, 40%);
				text-decoration: none;
			}
		}
		
		svg path.csepp {
			fill: @piros;
		}
		
		&.aloldal {
			.panel-title {
				color: @piros;
			}
			
			svg {
				background: @piros;
				margin-top: -4px;
				
				path.csepp {
					fill: @white;
				}
			}

		}
	}

	&.webshop {
		.bg-majdnemszurke;
		color: lighten(@majdnemszurke, 40%);
		
		svg path {
			fill: @majdnemszurke;
		}
		
		&.aloldal {
			.panel-title {
				color: @majdnemszurke;
			}
			
			svg {
				background: @majdnemszurke;
				margin-top: -4px;
				
				path {
					fill: @white;
				}
			}

		}
	}
	
	&.aloldal {
		.bg-feher;
		border: 4px solid #ccc;
		height: 110px;
		margin-bottom: @grid-gutter-width;
	}
}

#olajvalaszto-modal {
	svg {
		path.csepp {
			fill: @piros;
		}
	}
}

h1,h2,h3,h4,h5,h6 {
	color: @piros;
	.text-uppercase;

	&:after {
		content: '';
		display: block;
		width: @grid-gutter-width;
		border-bottom: 1px solid #bfbfbf;
		margin: @grid-gutter-width / 2 0;
	}
	
	&.nincsvonal:after {
		display: none;
		content: none;
	}
}

hr {
	margin-top:    @grid-gutter-width * 1.5;
	margin-bottom: @grid-gutter-width * 1.5;
}

.btn {
	.button-size(@padding-base-vertical; 3em; @font-size-base; @line-height-base; @border-radius-base);
}

.btn-default {
	&:hover,
	  &:focus,
	  &.focus,
	  &:active,
	  &.active,
	  .open > .dropdown-toggle& {
		color: @white;
		background-color: @piros;
			border-color: @piros;
	  }
}

.szurkesav {
	background: #f4f4f4;
	padding: @grid-gutter-width 0;
	box-shadow: inset 0px 20px 40px -20px #ccc;
	margin-bottom: @grid-gutter-width * 2;
	
	.form-group {
		margin-bottom: 0;
	}
}

.keresosav {
	margin-bottom: @grid-gutter-width;
	
	.form-group {
		margin-bottom: 0;
	}
	
	.kosar {
		.size(@container-md / @grid-columns - @grid-gutter-width, 45px + @grid-gutter-width);
		display: inline-block;
		margin-top: -@grid-gutter-width;
		background: @piros;
		color: @white;
		vertical-align: bottom;
		
		span {
			display: block;
			text-align: center;
			font-size: @font-size-large;
		}
		
		svg {
			display: block;
			margin: -12px auto 0 auto;
			
			path.kosar {
				fill: @white;
			}
		}
		
		@media (max-width: @screen-xs-max) {
			margin-top: 0;
			margin-bottom: -5px;
		}
	}
}

.termek {
	font-size: 14px;
	
	.caption h3 {
		margin-bottom: 0;
	}
	
	.akcio {
		position: absolute;
		right: 0;
		top: 0;
		padding: .2em .4em;
	}
	
	.size li+li:before {
		content: "/";
		padding-right: 5px;
		margin-left: -5px;
	}
	
	.popover {
		.hidden-sm;
	}
	
	.popover-title {
		border-bottom: 0 none;
		padding-bottom: 0;
	}
	
	.popover-content {
		padding-top: 0;
	}
}

.termek.termekek {
	@termek-height: 390px;
	@kosar-height: 70px;
	
	.thumbnail {
		height: @termek-height;
		padding-bottom: @kosar-height;
		position: relative;

		a.gradient {
			display: block;
			padding: 10px 0;
			background-color: rgb(238,238,238);
			background: -webkit-linear-gradient(rgb(244,244,244), rgb(232,232,232));
			background: -o-linear-gradient(rgb(244,244,244), rgb(232,232,232));
			background: -moz-linear-gradient(rgb(244,244,244), rgb(232,232,232));
			background: linear-gradient(rgb(244,244,244), rgb(232,232,232));
		}
	}
	
	.arak {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: @kosar-height;
	}
	
	.kosarbarak {
		.size(60px, @kosar-height);
		display: inline-block;
		background-color: #ccc;
		color: @white;
		vertical-align: bottom;
		.transition(background-color .2s ease-in-out);

		span {
			display: block;
			text-align: center;
			font-size: @font-size-large;
		}

		svg {
			display: block;
			margin: -5px auto 0 auto;

			path.kosar {
				fill: @white;
			}
		}
	}
	
	&:hover {
		.thumbnail {
			border-color: @piros;
		}
		
		.kosarbarak {
			background-color: @piros;
		}
	}
}

.termek.adatlap {
	.akcio {
		position: static;
		display: inline-block;
	}
	
	.mennyiseg {
		margin-bottom: @grid-gutter-width;
	}
}

form.kapcsolat {
	margin-top: @grid-gutter-width * 2;
}

textarea[name=uzenet] {
	height: @input-height-base * 4 + @form-group-margin-bottom * 3;
}

input[name=kapcsolat_ok] {
	padding-left: 3em;
	padding-right: 3em;
	.text-uppercase;
}

.rendeles-menete {
	font-size: 14px;
	margin-bottom: @grid-gutter-width * 2;
	
	.step {
		padding-top: @grid-gutter-width / 2;
		padding-bottom: @grid-gutter-width / 2;
		.text-center;
		.text-uppercase;
		
		&.active {
			color: @piros;
		}
		
		&:not(:last-child):after {
			content: '\e258';
			.glyphicon;
			display: block;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -.5em;
		}
		
		@media (max-width: (@screen-xs-min - 1)) {
			width: 100%;
			
			&:not(:last-child):after {
				content: '\e259';
				top: auto;
				right: auto;
				margin: .5em 0 -.5em;
				position: static;
			}
		}
		
		@media (min-width: @screen-xs-min) {
			width: 20%;
			display: table;

			span {
				display: table-cell;
				vertical-align: middle;
				height: @line-height-computed * 2;
				word-spacing: 35px;
			}
		}
	}
}

.table-kosar {
	&, th, td {
		font-size: 14px;
		line-height: @line-height-base;
	}
	
	>thead>tr>th {
		border-bottom: 0 none;
	}
	
	>tbody, >head, >tfoot {
		>tr:not(.solid-border) {
			background-image: @lista-border;
			background-position: 0 0;
			background-repeat: repeat-x;
			
			>td, >th {
				border-top: 0 none;
			}
		}
	}
	
	.kosarspinner {
		width: 35px;
	}
}

.ui-widget {
	&, input, select, textarea, button {
		font-family: @font-family-base;
	}
}

.ui-spinner {
	@spinner-height: 28px;
	
	height: @spinner-height;
	border-radius: 0;
	
	.ui-spinner-button {
		.glyphicon;
		color: @white;
		background: #ccc;
		display: block !important;
		position: absolute;
		right: 0;
		height: @spinner-height / 2;
		line-height: @spinner-height / 2;
		
		&.ui-spinner-up {
			.glyphicon-triangle-top;
			top: 0;
		}
		
		&.ui-spinner-down {
			.glyphicon-triangle-bottom;
			top: @spinner-height / 2;
		}
		
		span {
			display: none;
		}
	}
}

#payment-card {
	~.kartyainfo {
		overflow: hidden;
		max-height: 0;
		margin: 0;
		padding: 0;
		.transition-duration(.2s);
		.transition-timing-function(cubic-bezier(0, 1, 0.5, 1));
	}
	
	&:checked ~ .kartyainfo {
		overflow: hidden;
		max-height: 1000px;
		.transition-duration(.2s);
		.transition-timing-function(ease-in);
	}
}

.table-attekintes {
	td.img {
		width: 60px;
		
		@media (max-width: @screen-xs-max) {
			+td {
				text-indent: -60px;
			}
		}
	}
	
	img {
		max-width: 60px;
		max-height: 60px;
	}
	
	td {
		vertical-align: middle !important;
	}
}

body>header {
	.container {
		position: relative;
	}
}	

.login-info {
	position: absolute;
	top: 100%;
	right: @grid-gutter-width / 2;
	background: rgba(244,244,244,.7);
	padding: 0 1em;
			
	@media (max-width: @screen-sm-max){
		left: 0;
		right: 0;
		text-align: right;
	}

	&, .btn-link {
		font-size: @font-size-base;
		line-height: @line-height-base;
	}
}

#balmenu {
	.active {
		color: @piros !important;
	}
	
	.list-group-submenu {
		padding-left: 1em;
	}
}