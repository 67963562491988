//
// Wells
// --------------------------------------------------


// Base class
.well {
	margin: @grid-gutter-width 0;
	border: 0 none;
	.box-shadow(none);
	height: 230px;

	h1 {
		color: @majdnemszurke;
		.font-light;
		font-size: 46px;
		margin-bottom: @grid-gutter-width;

		&:after {
			content: none;
			display: none;
		}
	}

	p {
		color: @majdnemszurke;
	}
  
	&.termekek, &.rolunk {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;

		>a {
			position: absolute;
			display: block;
			bottom: 0;
			right: 0;
			left: 0;
			top: 0;

			>div {
				position: absolute;
				padding: @grid-gutter-width;
				bottom: 0;
				z-index: 2;
			}
			
			&:hover .hoverbg {
				.opacity(1);
			}
		}
	}
	
	&.termekek {
		background-image: url("@{images-url}bg-well-termekek.jpg");
	}
	
	&.rolunk {
		background-image: url("@{images-url}bg-well-rolunk.jpg");
	}
	
	.hoverbg {
		position: absolute;
		.img-responsive;
		.opacity(0);
		z-index: 1;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
		.transition(opacity .4s linear);
	}
}

