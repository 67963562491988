.modal-content {
	.box-shadow(0 5px 20px rgba(0,0,0,.3));
}

.modal-header {
	svg {
		background: @white;
		.pull-left;
		margin-right: @grid-gutter-width / 2;
		margin-top: -@modal-title-padding;
	}
}

.modal-title {
	color: @white;
	
	&:after {
		display: none;
		content: none;
	}
}