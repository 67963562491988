.navbar {
	border: 0 none;
}

.navbar .brand img {
	margin-top: (@navbar-height - 52px) / 2;
	
	@media (max-width: @grid-float-breakpoint-max) {
		margin-left: @grid-gutter-width / 2;
	}
}

.navbar-default {
	.navbar-nav {
		>li {
			.box-sizing(border-box);
			
			>a {
				font-size: @font-size-large;
				text-transform: uppercase;
				.font-medium;
			}
		}
		
		>li:hover:before {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 2px;
			background: @piros;
			content: '';
		}
	}
}

@media (max-width: @grid-float-breakpoint-max) {
	.navbar-nav {
		margin: 0;
		width: 100%;
		
		li {
			.text-right;
			
			&+li {
				border-top: 1px solid @gray-lighter;
			}
			
			a {
				font-size: 1.2em;
				line-height: 2em;
			}
		}
	}
}